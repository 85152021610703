
import { defineComponent } from 'vue'
import { ElConfigProvider } from 'element-plus'
export default defineComponent({
    components: {
        ElConfigProvider,
    },
    setup() {
        return {
            zIndex: 13000,
            // size: 'small',
        }
    },
})
