
import { defineComponent, ref } from 'vue';
export default defineComponent({
    name: 'Task',
    props: {
        icon: String,
        type: String,
        title: String,
        pid: Number
    },
    emits: ['click', 'dragend', 'dragenter', 'dragstart', 'dragover'],
    data() {
        return {
            IconName: ref(`fa fa-${this.icon}`),
        }
    },
    computed: {
        class1() {
            return `fa fa-${this.icon}`
        }
    },
    methods: {

    },
    mounted() {

    }
});

