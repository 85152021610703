import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view")
  const _component_el_config_provider = _resolveComponent("el-config-provider")

  return (_openBlock(), _createElementBlock("div", {
    onContextmenu: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _createVNode(_component_el_config_provider, {
      size: _ctx.size,
      "z-index": _ctx.zIndex
    }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }, 8, ["size", "z-index"])
  ], 32))
}