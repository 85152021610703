import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01e6c7a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = {
  key: 0,
  class: "des"
}
const _hoisted_3 = {
  key: 1,
  class: "title"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("span", {
    class: "show-title",
    title: _ctx.title,
    style: _normalizeStyle(_ctx.type == 'line'?{width: '100%'} :'')
  }, [
    (_ctx.type == 'task')
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass([_ctx.pid==-1?'pid-1':'', "main w-12 h-12"]),
          onDragenter: _cache[0] || (_cache[0] = $event => (_ctx.$emit('dragenter', $event))),
          onDragend: _cache[1] || (_cache[1] = $event => (_ctx.$emit('dragend', $event))),
          onDragstart: _cache[2] || (_cache[2] = $event => (_ctx.$emit('dragstart', $event))),
          onDragover: _cache[3] || (_cache[3] = $event => (_ctx.$emit('dragover', $event))),
          onClick: _cache[4] || (_cache[4] = $event => (_ctx.$emit('click')))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass([_ctx.class1, "icon"])
          }, null, 2),
          (_ctx.pid== -1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true)
        ], 34))
      : _createCommentVNode("", true),
    (_ctx.type == 'line')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true)
  ], 12, _hoisted_1))
}