import { defineComponent, ref } from 'vue';
import { windowIndex, indexAdd, newWindows, newWindowsDec } from '../modules/api';
export default defineComponent({
    name: 'Window',
    data: function () {
        return {
            isMaximized: false,
            style: {
                left: window.innerWidth < 700 ? 25 : window.innerWidth * 0.2 + newWindows * 20,
                top: window.innerWidth < 700 ? 25 : (window.innerHeight * 0.2 - 70) + newWindows * 20,
                width: window.innerWidth < 700 ? window.innerWidth - 50 : window.innerWidth * 0.6,
                height: window.innerWidth < 700 ? window.innerHeight - 70 - 50 : window.innerHeight * 0.6,
            },
            styleCopy: {
                left: window.innerWidth * 0.2 + newWindows * 20,
                top: (window.innerHeight * 0.2 - 70) + newWindows * 20,
                width: window.innerWidth < 700 ? window.innerWidth : window.innerWidth * 0.6,
                height: window.innerHeight * 0.6,
            },
            zindex: { 'z-index': windowIndex, display: 'block', opacity: 0 },
            isMinimized: false
        };
    },
    props: {
        name: String,
        icon: String,
        title: String,
        pid: Number
    },
    emits: ['close'],
    methods: {
        mousedown: function () {
            var main = ref(this.$refs.main);
            main.value.focus();
            indexAdd();
            this.zindex['z-index'] = windowIndex;
        },
        close: function () {
            var _this = this;
            this.zindex.opacity = 0;
            setTimeout(function () {
                _this.$emit('close', _this.pid);
            }, 200);
            // this.$emit('close', this.name)
        },
        maximize: function () {
            this.isMinimized = false;
            this.zindex.display = 'block';
            this.zindex.opacity = 1;
            var main = ref(this.$refs.main);
            main.value.focus();
            if (this.isMaximized) {
                this.style = this.styleCopy;
                main.value.style.left = this.styleCopy.left + 'px';
                main.value.style.top = this.styleCopy.top + 'px';
            }
            else {
                this.styleCopy = JSON.parse(JSON.stringify(this.style));
                this.style = {
                    left: 0,
                    top: 0,
                    width: window.innerWidth,
                    height: window.innerHeight - 70,
                };
            }
            this.isMaximized = !this.isMaximized;
            setTimeout(function () {
                main.value.style.transition = 'all 0s';
            }, 320);
        },
        minimize: function () {
            var _this = this;
            this.isMinimized = true;
            this.zindex.opacity = 0;
            setTimeout(function () {
                _this.zindex.display = 'none';
            }, 200);
            // this.zindex.display = 'none'
            // console.log(this.isMinimized);
        },
        showWindow: function () {
            // console.log(this.isMinimized);
            this.zindex.display = 'block';
            this.zindex.opacity = 1;
            this.isMinimized = false;
            // console.log(this.isMinimized);
            indexAdd();
            this.zindex['z-index'] = windowIndex;
            var main = ref(this.$refs.main);
            main.value.focus();
        },
        dragstop: function (left, top) {
            newWindowsDec();
            this.style.left = left;
            this.style.top = top;
        },
    },
    mounted: function () {
        var _this = this;
        this.zindex.opacity = 1;
        var main = ref(this.$refs.main);
        main.value.focus();
        // this.$router.push({ name: this.name })
        window.addEventListener('resize', function () {
            if (_this.isMaximized) {
                _this.style = {
                    left: 0,
                    top: 0,
                    width: window.innerWidth,
                    height: window.innerHeight - 70,
                };
            }
        });
    },
});
