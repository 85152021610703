export let windowIndex = 100
export function indexAdd() {
  windowIndex++
}
export let newWindows = 0
export function newWindowsAdd(){
    newWindows++
}
export function newWindowsDec(){
    newWindows--
}