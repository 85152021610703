import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-6f6802f5"), n = n(), _popScopeId(), n); };
var _hoisted_1 = { class: "header" };
var _hoisted_2 = { class: "buttons" };
var _hoisted_3 = { class: "router-div scroll-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_router_view = _resolveComponent("router-view");
    var _component_vue_draggable_resizable = _resolveComponent("vue-draggable-resizable");
    return (_openBlock(), _createBlock(_component_vue_draggable_resizable, {
        class: "window",
        onDragStop: _ctx.dragstop,
        resizeable: !_ctx.isMaximized,
        draggable: !_ctx.isMaximized,
        style: _normalizeStyle(_ctx.zindex),
        x: _ctx.style.left,
        y: _ctx.style.top,
        "min-width": 400,
        "min-height": 300,
        w: _ctx.style.width,
        h: _ctx.style.height,
        onMousedown: _withModifiers(_ctx.mousedown, ["stop"]),
        dragHandle: ".header",
        onDraging: _cache[8] || (_cache[8] = function () { })
    }, {
        default: _withCtx(function () { return [
            _createElementVNode("div", {
                class: "main",
                tabindex: "0",
                ref: "main",
                onKeydown: _cache[7] || (_cache[7] = _withKeys(_withModifiers(function ($event) { return (_ctx.close()); }, ["stop"]), ["esc"]))
            }, [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", null, [
                        _createElementVNode("i", {
                            class: _normalizeClass("fa fa-".concat(_ctx.icon))
                        }, null, 2)
                    ]),
                    _createElementVNode("div", {
                        class: "title",
                        onDblclick: _cache[0] || (_cache[0] = function ($event) { return (_ctx.maximize()); })
                    }, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.title), 1)
                    ], 32),
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("button", {
                            class: "minimize",
                            onClick: _cache[1] || (_cache[1] = function ($event) { return (_ctx.minimize()); }),
                            onMousedown: _cache[2] || (_cache[2] = _withModifiers(function () { }, ["prevent"]))
                        }, null, 32),
                        _createElementVNode("button", {
                            class: "maximize",
                            onClick: _cache[3] || (_cache[3] = function ($event) { return (_ctx.maximize()); }),
                            onMousedown: _cache[4] || (_cache[4] = _withModifiers(function () { }, ["prevent"]))
                        }, null, 32),
                        _createElementVNode("button", {
                            class: "close",
                            onClick: _cache[5] || (_cache[5] = function ($event) { return (_ctx.close()); }),
                            onMousedown: _cache[6] || (_cache[6] = _withModifiers(function () { }, ["prevent"]))
                        }, null, 32)
                    ])
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_router_view, {
                        class: "router scroll-container",
                        name: _ctx.name
                    }, null, 8, ["name"])
                ])
            ], 544)
        ]; }),
        _: 1
    }, 8, ["onDragStop", "resizeable", "draggable", "style", "x", "y", "w", "h", "onMousedown"]));
}
